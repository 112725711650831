<template>
    <div>
      <b-row>
          <b-col>
          <b-card class="my-card m-0">
              <b-table
                  striped
                  small
                  id="myTabless"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="tariffPriceWork"
                  :fields="fields"
                  head-row-variant="secondary"
                  @row-clicked="clicked"
                  > 
                  <template #cell(by_prices)="data">
                      <span v-for="(prices, i) in data.item.by_price" :key="i">
                            {{ prices.percent }}={{ prices.price }}%
                      </span>
                  </template>
                  <template #cell(by_distances)="data">
                      <span v-for="(distance, i) in data.item.by_distance" :key="i">
                            {{ distance.percent }}={{ distance.distance }}%
                      </span>
                  </template>
                  <template #cell(by_times)="data">
                      <span v-for="(time, i) in data.item.by_time" :key="i">
                            {{ time.percent }}={{ time.time }}%
                      </span>
                  </template>
              </b-table>
              </b-card> 
          </b-col>
      </b-row>
    </div>
</template>


<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js"
import { cellSelect } from "@/utils/TableFunctions/cellSelect";

export default {
    data() {
        return {
            tariffPriceWork: [],
            fields: [
                {key: 'id', label: 'ID', sortable: true, thStyle: {width: '10px'}},
                {key: 'base_tariff_performer_settings_id', label: 'ID настройки', sortable: true, thStyle: {width: '10px'}},
                {key: 'performer_request_type_name', label: 'Тип запроса водителя', sortable: true, thStyle: {width: '50px'}},
                {key: 'by_prices', label: 'По цене', sortable: true, thStyle: {width: '50px'}},
                {key: 'by_distances', label: 'Порасстоянию', sortable: true, thStyle: {width: '50px'}},
                {key: 'by_times', label: 'По время', sortable: true, thStyle: {width: '50px'}},
            ],
        }
    },
    mounted() {
        this.$store.commit('pageData/setdataCount', null)
        resizeable()
        this.refresh()
    },
    methods:{
        refresh(){
            this.$http.get(`tariff-performers/${this.$route.query.filter}/prices`)
                .then(res => {
                    this.tariffPriceWork = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffPriceWork.length)
                })
        },
        clicked(item, index, event){
              this.$store.state.filterPlus.tableIndex = index
  
              const clickedElement = event.target;
              const clickedCell = clickedElement.closest('td');
  
              if (!clickedCell) {
                  return;
              }
  
              const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);
  
              if (columnIndex < 0 || columnIndex >= this.fields.length) {
                  return;
              }
  
              const clickedField = this.fields[columnIndex];
              this.$store.state.filterPlus.keyFilter = clickedField.key;
              this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
              cellSelect()
          },
    },
    watch: {
    '$route.query.filter': function(newPage, oldPage) {
            if (newPage !== oldPage) {
          this.$http
                .get(`tariff-performers/${this.$route.query.filter}/prices`)
                .then(res => {
                    this.assigment = res.data
                    this.$store.commit('pageData/setdataCount', this.assigment.length)
                })
            }
        }
  }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }
</style>